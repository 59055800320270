import { MetaDefinition } from '@angular/platform-browser';
import { Product } from '@absdepot/data';
import { productDisplayName } from './product-display-name';

export const productMetaDefinitions = (product: Product): MetaDefinition[] => [
  { name: 'title', content: 'ABS Depot' },
  { name: 'description', content: productDisplayName(product) },
  { name: 'keywords', content: 'absdepot' },

  { name: 'og:type', content: 'product' },
  { name: 'og:title', content: `ABS Depot - ${productDisplayName(product)}` },
  { name: 'og:description', content: productDisplayName(product) },
  { name: 'og:image', content: product.image ?? '' },
  { name: 'og:url', content: `https://app.absdepot.com/product/${product.id}` },

  { name: 'twitter:card', content: 'summary_large_image' },
  {
    name: 'twitter:title',
    content: `ABS Depot - ${productDisplayName(product)}`,
  },
  { name: 'twitter:description', content: productDisplayName(product) },
  { name: 'twitter:image', content: product.image ?? '' },

  { name: 'author', content: 'ABS Depot Team' },
  { name: 'robots', content: 'index, follow' },
];
