import { Meta } from '@angular/platform-browser';
import { RedirectCommand, ResolveFn, Router } from '@angular/router';
import { Product } from '@absdepot/data';
import { inject } from '@angular/core';
import { ProductsService } from '../../../../core/services/products.service';
import { catchError, map, of } from 'rxjs';
import { AlertController } from '@ionic/angular/standalone';
import { productMetaDefinitions } from '../../../../core/utils/products/product-meta-definitions';

export const productResolver: ResolveFn<Product> = (route) => {
  const id = route.params.id;
  const alerts = inject(AlertController);
  const meta = inject(Meta);
  const products = inject(ProductsService);
  const router = inject(Router);

  const product = products.getItemFromCache(+id);
  if (product) {
    productMetaDefinitions(product).forEach((definition) =>
      meta.updateTag(definition),
    );
    return product;
  }

  return products.getItem(id).pipe(
    map((r) => r.data),
    catchError((err) => {
      console.log(err);
      alerts
        .create({
          message: 'No se ha podido cargar el producto. Intente mas tarde.',
        })
        .then((a) => a.present());
      return of(
        new RedirectCommand(router.parseUrl(''), {
          skipLocationChange: true,
        }),
      );
    }),
  );
};
