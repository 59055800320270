import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { ModalController } from '@ionic/angular/standalone';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../core/services/user.service';
import { RequestContactInfoModal } from '../modals/request-contact-info.modal';

export const hasPhoneNumber: CanMatchFn = async () => {
  const userService = inject(UserService);
  const modalController = inject(ModalController);
  const cachedUserData = userService.resource.value();

  if (Boolean(cachedUserData?.phone) || Boolean(cachedUserData?.whatsapp)) {
    return true;
  }

  const freshUserData = await firstValueFrom(userService.getUserData());
  if (freshUserData.phone || freshUserData.whatsapp) {
    return true;
  }

  const modal = await modalController.create({
    component: RequestContactInfoModal,
    breakpoints: [0, 0.7, 0.9],
    initialBreakpoint: 0.9,
  });
  await modal.present();
  const { data: submitted } = await modal.onWillDismiss<boolean>();
  return Boolean(submitted);
};
