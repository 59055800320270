import {
  AuthenticationService,
  AuthStatus,
} from '@absdepot/data/authentication';
import { inject } from '@angular/core';
import { CanMatchFn, RedirectCommand, Router } from '@angular/router';
import { map } from 'rxjs';
import { AppPath } from '../../app.routes';
import { UserService } from '../../core/services/user.service';

export const hasBeenInvited: CanMatchFn = () => {
  const router = inject(Router);
  const auth = inject(AuthenticationService);
  const user = inject(UserService);

  if (
    auth.status() === AuthStatus.NotAuthenticated ||
    auth.status() === AuthStatus.Error ||
    user.resource.value()?.invited_by
  ) {
    return true;
  }

  return user.getUserData().pipe(
    map((user) => Boolean(user?.invited_by)),
    map(
      (hasBeenInvited) =>
        hasBeenInvited ||
        new RedirectCommand(router.createUrlTree(['/', AppPath.Onboarding])),
    ),
  );
};
